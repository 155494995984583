/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tagTypesList } from '../../tag-types';

export const URLOPTIONS = {
  PRODUCTION_API: 'https://api-hrms.techwens.com',
  PRODUCTION: 'hrms.techwens.com',
  DEVELOPMENT_API: 'https://api-dev.creedage.com',
  DEVELOPMENT: 'dev.creedage.com',
  LOCAL_API: 'https://api-dev.creedage.com',
  LOCAL: 'http://localhost:3000/',
};
export const commonExtendedApi = '/v1/api';
const { hostname } = window.location;
let BaseURL = URLOPTIONS.LOCAL_API + commonExtendedApi;

if (hostname.includes(URLOPTIONS.DEVELOPMENT)) {
  BaseURL = URLOPTIONS.DEVELOPMENT_API + commonExtendedApi;
}
if (hostname.includes(URLOPTIONS.PRODUCTION)) {
  BaseURL = URLOPTIONS.PRODUCTION_API + commonExtendedApi;
}

const API_URL = BaseURL;

const globalApiSlice = createApi({
  reducerPath: 'hrmsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = JSON.parse(localStorage.getItem('x-access-token'));
      if (token) {
        headers.set('x-access-token', token);
      }

      return headers;
    },
  }),
  tagTypes: tagTypesList,
  endpoints: (builder) => ({}),
});

export default globalApiSlice;
